import { defineStore } from 'pinia';

interface ModalState {
    isNewsletter: boolean,
    modals: {
        showSubscribeModal: boolean,
        showSubscribeSuccessModal: boolean,
    },
}

export const useSubscribeModalStore = defineStore({
    id: 'subscribeModalStore',
    state: (): ModalState => ({
        isNewsletter: false,
        modals: {
            showSubscribeModal: false,
            showSubscribeSuccessModal: false,
        },
    }),
    actions: {
        setSubscribeModal(value: boolean, isNewsletter = false) {
            this.modals.showSubscribeModal = value;

            if (value) {
                this.isNewsletter = isNewsletter;
            }
        },
        setSubscribeSuccessModal(value: boolean, isNewsletter = true) {
            this.modals.showSubscribeSuccessModal = value;

            if (isNewsletter) {
                this.isNewsletter = true;
            }

            if (!value) {
                this.isNewsletter = false;
            }
        },
    },
});
